import React from "react";
import PropTypes from "prop-types";

class EditFishForm extends React.Component {
	nameRef = React.createRef();
	priceRef = React.createRef();
	statusRef = React.createRef();
	descRef = React.createRef();
	imageRef = React.createRef();
	submitRef = React.createRef();

	static propTypes = {
		fish: PropTypes.shape({
			image: PropTypes.string,
			name: PropTypes.string,
			desc: PropTypes.string,
			status: PropTypes.string,
			price: PropTypes.number
		}),
		index: PropTypes.string,
		removeFish: PropTypes.func,
		updateFish: PropTypes.func
	}

	handleChange = (e) =>{
		const target = e.currentTarget;
		const updatedFish = {
			...this.props.fish,
			[target.name]: target.value
		};
		this.props.updateFish(this.props.index, updatedFish);

	}

	handleRemoveFish = (e) => {
		e.preventDefault();
		this.props.removeFish(this.props.index);
	}

	render() {
		const fish = this.props.fish;
		return (
			<form className="fish-edit">
				<input name="name" ref={this.nameRef} type="text"  onChange={this.handleChange} value={fish.name} />
				<input name="price" ref={this.priceRef} type="text"  onChange={this.handleChange} value={fish.price} />
				<select name="status" ref={this.statusRef}  onChange={this.handleChange} value={fish.status}>
					<option value="available" >Fresh!</option>
					<option value="unavailable" >Sold Out!</option>
				</select>
				<textarea name="desc" ref={this.descRef} onChange={this.handleChange} value={fish.desc}></textarea>
				<input name="image" ref={this.imageRef} type="text"  onChange={this.handleChange} value={fish.image} />
				<button onClick={this.handleRemoveFish} ref={this.submitRef}>Remove Fish</button>
			</form>
		)
	}
}

export default EditFishForm;