import React from "react";
import PropTypes from "prop-types";
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import { formatPrice } from "../helpers";

class Order extends React.Component {
	static propTypes = {
		fishes: PropTypes.object,
		order: PropTypes.object,
		removeLineItem: PropTypes.func
	}
	renderOrder = (key) => {
		const fish = this.props.fishes[key];
		const count = this.props.order[key];
		const transitionOptions = {
			classNames: "order",
			key: key,
			timeout: {enter: 500, exit: 500}
		}
		if (!fish) return null;
		if(fish.status !== 'available'){
			return (
				<CSSTransition {...transitionOptions}>
					<li key={key}>Sorry {fish ? fish.name : 'fish'} is no longer available<button onClick={() => {this.props.removeLineItem(key)}} >&times;</button></li>
				</CSSTransition>
			)
		}
		return (
			<CSSTransition {...transitionOptions}>
				<li key={key}>
					<span>
						<TransitionGroup component="span" className="count">
							<CSSTransition classNames="count" key={count} timeout={{enter: 500, exit: 500}}>
								<span>{count}</span>
							</CSSTransition>
						</TransitionGroup>
						lbs {fish.name} {formatPrice(count * fish.price)}
						<button onClick={() => {this.props.removeLineItem(key)}}>&times;</button>
					</span>
				</li>
			</CSSTransition>
		)
	}
	render(){
		const orderIds = Object.keys(this.props.order);
		const total = orderIds.reduce((prevTotal, key) => {
			const fish = this.props.fishes[key];
			const count = this.props.order[key];
			const isAvailable = fish && fish.status === 'available';

			if(isAvailable) {
				return prevTotal + (count * fish.price);
			}
			return prevTotal;
		}, 0)

		return (
			<div className="order-wrap">
			<h2>Order</h2>
				<TransitionGroup component='ul' className="order">
				{
					orderIds.map((key) => this.renderOrder(key))
				}
				</TransitionGroup>
				<h1>
					Total: <strong>{formatPrice(total)}</strong>
				</h1>
			</div>
		)
	}
}

export default Order;