import React from 'react';
import PropTypes from "prop-types";
import {getFunName} from '../helpers'

class StorePicker extends React.Component {
	myInput = React.createRef();

	static propTypes = {
		history: PropTypes.object
	}

	goToStore = (e) => {
		e.preventDefault();

		const storeName = this.myInput.current.value;

		this.props.history.push(`/store/${storeName}`);
	}

	render() {
		return (
			<form className="store-selector" onSubmit={this.goToStore}>
				<h2>Please Enter A Store</h2>
				<input type="text" required placeholder="Store Name" ref={this.myInput} defaultValue={getFunName()}/>
				<button type="submit">Visit Store! 👉</button>
			</form>
		)
	}
}

export default StorePicker;