import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: "AIzaSyB_S_PLimFK8LhYNiardu9RDrfJuQYh0d0",
	authDomain: "catch-of-the-day-76066.firebaseapp.com",
	databaseURL: "https://catch-of-the-day-76066-default-rtdb.firebaseio.com",
 });

 const base = Rebase.createClass(firebaseApp.database());

 export { firebaseApp }

 export default base;