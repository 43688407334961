import React from "react";
import PropTypes from "prop-types";

const Login = (props) => (
	<nav className="login">
		<h2>Login</h2>
		<p>Sign in to manage your stores inventory</p>
		<button className="facebook" onClick={() => props.authenticate('Facebook')} >Login to Facebook</button>
		<button className="github" onClick={() => props.authenticate('Github')} >Login to Github</button>
	</nav>
);

Login.protoTypes = {
	authenticate: PropTypes.func.isRequired
}

export default Login;