import React from "react";
import PropTypes from "prop-types";
import Header from "./Header"
import Inventory from "./Inventory";
import Order from "./Order";
import Fish from "./Fish";
import sampleFishes from '../sample-fishes';
import base from '../base';

class App extends React.Component {
	state = {
		fishes: {},
		order: {}
	};

	static propTypes = {
		match: PropTypes.object
	 };

	componentDidMount(){
		const params = this.props.match.params;
		const localStorageRef = localStorage.getItem(params.storeId);
		if(localStorageRef){
			this.setState({
				order: JSON.parse(localStorageRef)
			})
		}
		this.ref = base.syncState(`${params.storeId}/fishes`, {
			context: this,
			state: 'fishes'
		});
	}

	componentDidUpdate(){
		const params = this.props.match.params;
		localStorage.setItem(params.storeId,JSON.stringify(this.state.order));
	}

	componentWillUnmount(){
		base.removeBinding(this.ref);
	}

	addFish = (fish) => {
		const fishes = {...this.state.fishes};
		fishes[`fish${Date.now()}`] = fish;
		this.setState({
			fishes: fishes
		})
	}

	updateFish = (key, fish) => {
		const fishes = {...this.state.fishes};
		fishes[key] = fish;
		this.setState({
			fishes: fishes
		})
	}

	removeFish = (key) => {
		const fishes = {...this.state.fishes};
		fishes[key] = null;
		this.setState({
			fishes: fishes
		})
	}

	removeLineItem = (key) => {
		const order = {...this.state.order};
		delete order[key];
		console.table(order);
		this.setState({
			order: order
		});
		console.table(this.state.order);
	}

	loadSampleFishes = () => {
		this.setState({
			fishes: sampleFishes
		})
	}

	addToOrder = (key) => {
		const order = {...this.state.order};
		order[key] = order[key] + 1 || 1;
		this.setState({ order: order});
	}

	render(){
		return (
			<div className="catch-of-the-day">
				<div className="menu">
					<Header tagline="Fresh Seafood Market" />
					<ul className="fishes">
					{Object.keys(this.state.fishes).map((key) => {
						let fish = this.state.fishes[key];
						return <Fish key={key} index={key} fish={fish} addToOrder={this.addToOrder} />
					})}

					</ul>
				</div>
				<Order order={this.state.order} fishes={this.state.fishes} removeLineItem={this.removeLineItem} />
				<Inventory addFish={this.addFish} loadSampleFishes={this.loadSampleFishes} fishes={this.state.fishes} updateFish={this.updateFish} removeFish={this.removeFish} storeId={this.props.match.params.storeId} />
			</div>
		)
	}
}

export default App;